import React from 'react'
import {
    HeaderContainer,
    ContentContainer,
    Link,
    Header,
    IconLink,
    UpArrow,
} from './components'
import styled from 'styled-components'
import DiscordIcon from './img/discord-icon.png'
import XIcon from './img/x-icon.svg'
import MailIcon from './img/mail-icon.png'
import RedditIcon from './img/reddit-icon.svg'

const ContactContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
`
const ContactFooter = styled.div`
    width: 100%;
    text-align: center;
`

export const Contact = () => (
    <>
        <HeaderContainer id="contact">
            <UpArrow>
                <Link href="#friends">&uArr;</Link>
            </UpArrow>
            <Header>Contact</Header>
        </HeaderContainer>
        <ContentContainer>
            <ContactContainer>
                <Link
                    href="https://discord.gg/Cbe7eyHD75"
                    rel="noreferrer"
                    target="_blank"
                >
                    <IconLink src={DiscordIcon} alt="Discord icon" />
                </Link>
                <Link
                    href="https://twitter.com/WoofSolana"
                    rel="noreferrer"
                    target="_blank"
                >
                    <IconLink src={XIcon} alt="X icon" />
                </Link>
                <Link
                    href="https://reddit.com/r/woofsolana"
                    rel="noreferrer"
                    target="_blank"
                >
                    <IconLink src={RedditIcon} alt="Reddit icon" />
                </Link>
                <Link
                    href="mailto:team@woof.ventures"
                    rel="noreferrer"
                    target="_blank"
                >
                    <IconLink src={MailIcon} alt="E-mail icon" />
                </Link>
            </ContactContainer>
            <ContactFooter>
                For more WOOF resources, please see the{' '}
                <Link
                    href="https://links.woof.ventures"
                    target="_blank"
                    rel="noreferrer"
                >
                    WOOF link tree
                </Link>
                .
            </ContactFooter>
        </ContentContainer>
    </>
)
