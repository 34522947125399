import { PublicKey } from '@solana/web3.js'

export const roundToDecimal = (
    value: number,
    decimals: number | undefined | null
) => {
    return decimals
        ? Math.round(value * 10 ** decimals) / 10 ** decimals
        : Math.round(value)
}

export function getDecimalCount(value: number) {
    if (
        !isNaN(value) &&
        Math.floor(value) !== value &&
        value.toString().includes('.')
    )
        return value.toString().split('.')[1].length || 0
    if (
        !isNaN(value) &&
        Math.floor(value) !== value &&
        value.toString().includes('e')
    )
        return parseInt(value.toString().split('e-')[1] || '0')
    return 0
}

export const formatToReadableNumber = (number: number, decimals?: number) => {
    let decimalCount = 10
    if (!decimalCount && number) {
        const parsedDecimalCount = getDecimalCount(number)
        if (parsedDecimalCount < 10) {
            decimalCount = parsedDecimalCount
        }
    } else if (decimals && decimals <= 10) {
        decimalCount = decimals
    }
    return number
        ? Intl.NumberFormat('fr-CA', {
              maximumFractionDigits: 0,
          })
              .format(number)
              .replace(',', '.')
        : ''
}

export const parseStringToDecimal = (number: string, decimals: number) => {
    const amountAsNumber = roundToDecimal(parseFloat(number), decimals || 1)
    return amountAsNumber && !isNaN(amountAsNumber)
        ? roundToDecimal(amountAsNumber * 10 ** (decimals || 1), decimals)
        : undefined
}

export const getJupiterFeeAccount = async (mint: string): Promise<string> => {
    const [feeAccount] = await PublicKey.findProgramAddressSync(
        [
            Buffer.from('referral_ata'),
            new PublicKey(
                'CeKTkjtTB1EfPSb3TchAuBVwueh4s6YPGuzB9WwgPz8J'
            ).toBuffer(),
            new PublicKey(mint).toBuffer(),
        ],
        new PublicKey('REFER4ZgmyYx9c6He5XfaTMiGfdLwRnkV4RPp9t9iF3') // the Referral Program
    )
    return feeAccount.toBase58()
}
