import React from 'react'
import styled from 'styled-components'
import DiscordIcon from './img/discord-icon.png'
import XIcon from './img/x-icon.png'
import { Link } from './components'

const ImageContainer = styled.div`
    padding-left: 2rem;
`

const Container = styled.div`
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    flex: 1;
    padding: 2rem 0 2rem 0;
    max-width: 50%;

    p {
        line-height: 1.5rem;
        margin: 0;
        font-size: 1.125rem;
    }
    h2 {
        margin-bottom: 0.5rem;
    }

    @media screen and (min-width: 1200px) {
        border: 0;
    }

    @media screen and (min-width: 2000px) {
        max-width: 33%;
    }
`

const ContactIcon = styled.img`
    width: 32px;
    height: auto;
    border-radius: 0;
    margin-right: 1rem;
`

const Image = styled.img`
    border-radius: 50%;
    width: 78px;
    height: auto;

    @media screen and (min-width: 1200px) {
        width: 200px;
    }
    @media screen and (min-width: 1500px) {
        width: 256px;
    }
`

const ContactSection = styled.div`
    margin-top: 1.5rem;
    display: flex;
    flex-flow: column;
    a {
        display: flex;
        flex-flow: row;
        margin-bottom: 1rem;
        align-items: center;
        font-size: 1.125rem;
    }
`

const DetailsContainer = styled.div`
    margin-right: 3rem;
    margin-left: 2rem;
`

const MemberName = styled.h2`
    margin-top: 0;
`

type TeamMember = {
    imageSrc: string
    name: string
    title: string
    subtitle?: string
    discordId: string
    discordUsername: string
    twitterAccount: string
}

export const TeamMember = (props: TeamMember) => (
    <Container>
        <ImageContainer>
            <Image src={props.imageSrc} alt={props.name} />
        </ImageContainer>
        <DetailsContainer>
            <MemberName>{props.name}</MemberName>
            <p>{props.title}</p>
            {props.subtitle && <p>{props.subtitle}</p>}
            <ContactSection>
                <div>
                    <Link
                        href={`https://discordapp.com/users/${props.discordId}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <ContactIcon src={DiscordIcon} />
                        {props.discordUsername}
                    </Link>
                </div>
                <div>
                    <Link
                        href={`https://twitter.com/${props.twitterAccount.toLowerCase()}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <ContactIcon src={XIcon} />@{props.twitterAccount}
                    </Link>
                </div>
            </ContactSection>
        </DetailsContainer>
    </Container>
)
