import React, { useState, useMemo } from 'react'
import styled from 'styled-components'
import { Link, IconLink } from './components'
import { JupiterSwap } from './JupiterSwap'
import axios from 'axios'
import CoinGecko from './img/coingecko.png'
import CoinmarketCap from './img/coinmarketcap.png'
import SolScan from './img/solscan.png'
import CryptoCom from './img/cryptocom.png'
import BirdEye from './img/birdeye.png'

const Row = styled.div`
    display: flex;
    flex-flow: column;
    width: 100%;

    @media screen and (min-width: 1600px) {
        flex-flow: row;
        flex: 1;
    }
`

const InfoContainer = styled.div`
    width: 100%;
`

const JupiterSwapContainer = styled.div`
    position: relative;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    @media screen and (min-width: 1600px) {
        margin-left: 4rem;
        justify-content: start;
    }
`

const LinksContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    img {
        height: 50px;
        margin: 1rem;

        @media screen and (min-width: 1600px) {
            height: 75px;
        }
    }
    margin-bottom: 1rem;
    justify-content: center;

    @media screen and (min-width: 1600px) {
        justify-content: start;
    }
`

export const WoofToken = () => {
    const [marketCap, setMarketCap] = useState<number>(0.5)

    useMemo(() => {
        axios
            .get('https://api.woofsolana.world/woofPrice')
            .then(
                ({
                    data,
                }: {
                    data: {
                        mcInMillions: number
                    }
                }) => {
                    setMarketCap(data.mcInMillions)
                }
            )
            .catch((err: Error) => {
                console.error(err)
            })
    }, [])

    return (
        <Row>
            <InfoContainer>
                <ul>
                    <li style={{ wordBreak: 'break-word' }}>
                        Contract address:{' '}
                        <b>9nEqaUcb16sQ3Tn1psbkWqyhPdLmfHWjKGymREjsAgTE</b>
                    </li>
                    <li>
                        Launched in May, 2021. <b>98%</b> of the initial supply
                        was burned.
                    </li>
                    <li>An OG Solana community/meme token.</li>
                    <li>
                        Max supply: <b>17.9B</b>
                    </li>
                    <li>
                        Circulating supply: <b>17.9B</b>
                    </li>
                    <li>
                        Top 10 wallets: <b>&lt; ~20%</b>
                    </li>
                    <li>
                        Holders (wallets): <b>~30 000</b>
                    </li>
                    <li>
                        Fully diluted market cap: <b>{marketCap}M</b>
                    </li>
                    <li>
                        Available for trading on e.g.{' '}
                        <Link
                            href="https://jup.ag/swap/SOL-WOOF"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Jupiter
                        </Link>
                        ,{' '}
                        <Link
                            href="https://saros.finance/swap?base=So11111111111111111111111111111111111111112&pair=9nEqaUcb16sQ3Tn1psbkWqyhPdLmfHWjKGymREjsAgTE"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Saros
                        </Link>
                        ,{' '}
                        <Link
                            href="https://dex.woof.ventures"
                            target="_blank"
                            rel="noreferrer"
                        >
                            WOOF DEX
                        </Link>
                        ,{' '}
                        <Link
                            href="https://raydium.io/swap/?inputCurrency=sol&outputCurrency=9nEqaUcb16sQ3Tn1psbkWqyhPdLmfHWjKGymREjsAgTE&fixed=in"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Raydium
                        </Link>
                        .
                    </li>
                    <li>
                        Can also be purchased using the $WOOF QuickSwap
                        available right here.
                    </li>
                </ul>
                <LinksContainer>
                    <Link
                        href="https://solscan.io/token/9nEqaUcb16sQ3Tn1psbkWqyhPdLmfHWjKGymREjsAgTE"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <IconLink src={SolScan} alt="Solscan" />
                    </Link>
                    <Link
                        href="https://coinmarketcap.com/currencies/woof/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <IconLink src={CoinmarketCap} alt="CoinMarketCap" />
                    </Link>
                    <Link
                        href="https://www.coingecko.com/en/coins/woof"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <IconLink src={CoinGecko} alt="CoinGecko" />
                    </Link>
                    <Link
                        href="https://crypto.com/price/woof"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <IconLink src={CryptoCom} alt="Crypto.com" />
                    </Link>
                    <Link
                        href="https://birdeye.so/token/9nEqaUcb16sQ3Tn1psbkWqyhPdLmfHWjKGymREjsAgTE?chain=solana"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <IconLink src={BirdEye} alt="BirdEye" />
                    </Link>
                </LinksContainer>
            </InfoContainer>
            <JupiterSwapContainer>
                <JupiterSwap />
            </JupiterSwapContainer>
        </Row>
    )
}
