import React from 'react'
import {
    Header,
    HeaderContainer,
    Separator,
    DownArrow,
    Link,
    UpArrow,
} from './components'
import { TeamMember } from './TeamMember'
import styled from 'styled-components'
import aguntor from './img/aguntor.png'
import tnr from './img/tnr.png'
import bob from './img/bob.png'
import solegendary from './img/soleg.png'
import solDaddy from './img/soldaddy.png'
import altarBoi from './img/altarboi.png'
import berta from './img/berta.png'
import dera from './img/dera.jpg'
import roba from './img/robasmith.jpg'

const TeamContainer = styled.div`
    display: flex;
    flex-flow: row;
    margin-top: 2rem;
    flex-wrap: wrap;
    columns: 2rem auto;
`

const MainTeamContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
`

export const Team = () => (
    <>
        <HeaderContainer id="team">
            <UpArrow>
                <Link href="#about">&uArr;</Link>
            </UpArrow>
            <Header>Team</Header>
            <DownArrow>
                <Link href="#token">&dArr;</Link>
            </DownArrow>
        </HeaderContainer>
        <MainTeamContainer>
            <TeamContainer>
                <TeamMember
                    imageSrc={aguntor}
                    name={'aguntor'}
                    title={'Director'}
                    subtitle={'Technology & development'}
                    discordId={'381807923397132290'}
                    discordUsername={'aguntor'}
                    twitterAccount={'agntrc'}
                />
                <Separator $mobileonly={true} />
                <TeamMember
                    imageSrc={tnr}
                    name={'tnr'}
                    title={'Finances'}
                    discordId={'226425097462874112'}
                    discordUsername={'tnr7333'}
                    twitterAccount={'tnr_woof'}
                />
                <Separator $mobileonly={true} />
                <TeamMember
                    imageSrc={solDaddy}
                    name={'SolDaddy'}
                    title={'Socials'}
                    subtitle={'Marketing'}
                    discordId={'906608409569296464'}
                    discordUsername={'solanadad42069'}
                    twitterAccount={'SolanaDad'}
                />
                <Separator $mobileonly={true} />
                <TeamMember
                    imageSrc={solegendary}
                    name={'solegendary'}
                    title={'Legal'}
                    discordId={'1134952406233387008'}
                    discordUsername={'solegendary.'}
                    twitterAccount={'solegendary1312'}
                />
                <Separator $mobileonly={true} />
                <TeamMember
                    imageSrc={altarBoi}
                    name={'AltarBoi'}
                    title={'Community'}
                    discordId={'909577411597844530'}
                    discordUsername={'altar_boi'}
                    twitterAccount={'DevonCarey18'}
                />
                <Separator $mobileonly={true} />
                <TeamMember
                    imageSrc={roba}
                    name={'Robasmith'}
                    title={'Community'}
                    discordId={'832661573326864394'}
                    discordUsername={'robasmith.sol'}
                    twitterAccount={'Woofmaxi'}
                />
                <Separator $mobileonly={true} />
                <TeamMember
                    imageSrc={dera}
                    name={'dera'}
                    title={'Socials'}
                    discordId={'889786583883055126'}
                    discordUsername={'dera.sol'}
                    twitterAccount={'stuckincircles'}
                />
                <Separator $mobileonly={true} />
                <TeamMember
                    imageSrc={berta}
                    name={'Roberta.Fettucine'}
                    title={'Partnerships'}
                    subtitle={'Community'}
                    discordId={'1042091550961848320'}
                    discordUsername={'pazuzuposter'}
                    twitterAccount={'pazuzuposter'}
                />
                <Separator $mobileonly={true} />
                <TeamMember
                    imageSrc={bob}
                    name={'Bob'}
                    title={'Community'}
                    discordId={'594882368171540503'}
                    discordUsername={'floor_ice'}
                    twitterAccount={'FloorIce39'}
                />
            </TeamContainer>
        </MainTeamContainer>
    </>
)
