import React from 'react'
import WoofVenturesLogo from './img/logo.png'
import SolanaLogo from './img/solana-logo.svg'
import WoofLogo from './img/woof-transparent-bg.svg'
import styled from 'styled-components'
import {
    Link,
    Header,
    MainContainer,
    ParagraphDark,
    LogoContainer,
    Section,
    HeaderContainer,
    ContentContainer,
    DownArrow,
    UpArrow,
} from './components'
import './App.css'
import { Team } from './Team'
import { Contact } from './Contact'
import { WoofToken } from './WoofToken'
import { NFTs } from './NFTs'
import { Wallet as WalletProvider } from './WalletConnector'
import { About } from './About'
import { Friends } from './Friends'
import { Services } from './Services'
import { WoofDisclaimer } from './Disclaimer'

const WoofSolanaContainer = styled.div`
    display: flex;
    flex-flow: row;
    margin: 2rem 2rem 1rem 2rem;
    justify-content: center;
    align-items: center;
    img {
        max-height: 50px;
        width: auto;
        margin: 0 1rem 0 1rem;
    }
    font-size: 3rem;
`

const Copyright = styled(ParagraphDark)`
    padding: 1rem;
    font-size: 1.25rem;
    margin-top: 0;
    background: transparent;
`

function App() {
    return (
        <>
            <WoofDisclaimer />
            <MainContainer>
                <LogoContainer>
                    <img src={WoofVenturesLogo} alt="logo" />
                </LogoContainer>
                <ParagraphDark>
                    Welcome to Woof!
                    <br /> We are a Web 3 community focused on building on the{' '}
                    <Link
                        href="https://solscan.io/token/9nEqaUcb16sQ3Tn1psbkWqyhPdLmfHWjKGymREjsAgTE"
                        target="_blank"
                        rel="noreferrer"
                    >
                        WOOF token
                    </Link>{' '}
                    and NFTs created by the original "WoofSolana" project and
                    DAO on the{' '}
                    <Link
                        href="https://www.solana.com"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Solana
                    </Link>{' '}
                    ecosystem. Read more about our mission below.
                    <WoofSolanaContainer>
                        <img src={WoofLogo} alt="Solana" />
                        &#9829;
                        <img src={SolanaLogo} alt="Solana" />
                    </WoofSolanaContainer>
                </ParagraphDark>
                <Section>
                    <HeaderContainer id="about">
                        <Header>What &amp; Why?</Header>
                        <DownArrow>
                            <Link href="#team">&dArr;</Link>
                        </DownArrow>
                    </HeaderContainer>
                    <ContentContainer>
                        <About />
                    </ContentContainer>
                </Section>
                <Section>
                    <Team />
                </Section>
                <Section>
                    <HeaderContainer id="token">
                        <UpArrow>
                            <Link href="#team">&uArr;</Link>
                        </UpArrow>
                        <Header>$WOOF Token</Header>
                        <DownArrow>
                            <Link href="#nfts">&dArr;</Link>
                        </DownArrow>
                    </HeaderContainer>
                    <ContentContainer>
                        <WalletProvider>
                            <WoofToken />
                        </WalletProvider>
                    </ContentContainer>
                </Section>
                <Section>
                    <NFTs />
                </Section>
                <Section>
                    <Services />
                </Section>
                <Section>
                    <Friends />
                </Section>
                <Section>
                    <Contact />
                </Section>
                <Copyright>
                    &copy; WOOFers of Solana {new Date().getFullYear()}
                </Copyright>
            </MainContainer>
        </>
    )
}

export default App
