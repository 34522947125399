import React, { useMemo, useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import { Separator, Button, Link } from './components'
import {
    formatToReadableNumber,
    getJupiterFeeAccount,
    parseStringToDecimal,
} from './utils'
import { WoofWalletSelectButton, WOOF_RPC_ENDPOINT } from './WalletConnector'
import { JupiterQuote, JupiterToken, TokenOption } from './types'
import Select, { DomProps } from 'react-select-search'
import { ValueProps } from 'react-select-search'
import { useWallet } from '@solana/wallet-adapter-react'
import { Connection, VersionedTransaction } from '@solana/web3.js'
import WoofLogo from './img/woof-transparent-bg.svg'

const connection = new Connection(WOOF_RPC_ENDPOINT)
const MAINNET_BETA_ID = 101
const REFRESH_INTERVAL = 30 * 1000
const SOLANA_TOKEN_ADDRESS = 'So11111111111111111111111111111111111111112'
const WOOF_TOKEN_ADDRESS = '9nEqaUcb16sQ3Tn1psbkWqyhPdLmfHWjKGymREjsAgTE'
const SwapContainer = styled.div`
    position: relative;
    display: flex;
    flex-flow: column;
    border: 1px solid rgba(22, 45, 56, 0.8);
    padding: 2rem;
    width: 400px;
    background-color: rgba(0, 0, 0, 0.5);
`
const SwapHeader = styled.h3`
    position: absolute;
    top: -50px;

    font-family: 'Bebas Neue', sans-serif;
`
const OptionContainer = styled.div`
    display: flex;
    flex-flow: row;
    z-index: 3;
`
const OptionImageContainer = styled.div`
    display: flex;
    align-items: center;
    img {
        max-height: 32px;
        width: 32px;
        width: auto;
    }
    min-width: 50px;
    margin-right: 0.5rem;
`
const OptionDetails = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: start;
    align-items: start;
    flex-wrap: wrap;
`

const SubLabel = styled.div`
    font-size: 1rem;
`

const OptionButton = styled(Button)<{ $index: number }>`
    background-color: ${(props) =>
        props.$index % 2 === 0
            ? 'rgba(0, 0, 0, 0.7)'
            : 'rgba(22, 45, 56, 0.6)'};
`

const SwapButton = styled(Button)`
    margin-top: 1.5rem;
    background-color: rgba(22, 45, 56, 0.4);
    border: 1px solid rgba(22, 45, 56, 0.4);
    z-index: 1;
`

const TextInput = styled.input`
  background: transparent;
  font-size: 1.125rem;
  font: inherit;
  outline; inherit;
  color: inherit;
  font-size: 1rem;
  outline: inherit;
  border: 1px solid rgba(22, 45, 56, 0.8);
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  font-weight: bold;
  z-index: 1;
`

const OptionInput = styled(TextInput)<{
    $snapshot: { option: TokenOption }
}>`
    background-image: url('${(props) => props.$snapshot?.option?.logoURI}');
    background-repeat: no-repeat;
    background-size: 32px;
    background-position: 3% 50%;
    padding-left: 3.5rem;
    height: 60px;
`

const InputLabel = styled.label`
    margin: 0.5rem 0 0.25rem 0;
`

const OutputContainer = styled.div`
    line-height: 1.5rem;
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    color: #08ffb2;
    img {
        margin-right: 1rem;
    }
    height: 40px;
    z-index: 1;
`

const HelpTextContainer = styled(OutputContainer)`
    color: #ccc;
    font-size: 1.25rem;
    z-index: 1;
`

const Error = styled.span`
    color: red;
`

const LoadingAnimation = keyframes`
0% { background-size: 10px 3px;}
16% { background-size: 10px 50px, 10px 3px, 10px 3px, 10px 3px, 10px 3px, 10px 3px}
33% { background-size: 10px 30px, 10px 50px, 10px 3px, 10px 3px, 10px 3px, 10px 3px}
50% { background-size: 10px 10px, 10px 30px, 10px 50px, 10px 3px, 10px 3px, 10px 3px}
66% { background-size: 10px 3px, 10px 10px, 10px 30px, 10px 50px, 10px 3px, 10px 3px}
83% { background-size: 10px 3px, 10px 3px,  10px 10px, 10px 30px, 10px 50px, 10px 3px}
100% { background-size: 10px 3px, 10px 3px, 10px 3px,  10px 10px, 10px 30px, 10px 50px}
`
const Loading = styled.span`
    transform: scale(0.8);
    position: relative;
    width: 85px;
    height: 40px;
    margin-top: 0.5rem;
    background-repeat: no-repeat;
    background-image: linear-gradient(#fff 50px, transparent 0),
        linear-gradient(#a5d9f8 50px, transparent 0),
        linear-gradient(#fff 50px, transparent 0),
        linear-gradient(#a5d9f8 50px, transparent 0),
        linear-gradient(#fff 50px, transparent 0),
        linear-gradient(#a5d9f8 50px, transparent 0);
    background-position: 0px center, 15px center, 30px center, 45px center,
        60px center, 75px center, 90px center;
    animation: ${LoadingAnimation} 0.65s linear infinite alternate;
`

const FadeAnim = keyframes`
    0% {
      opacity: 0;
    }
    50% {
        opacity: 0.05;
    }
    100% {
      opacity: 0;
    }
`

const WoofImage = styled.img`
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    animation: ${FadeAnim} 8s infinite linear;

    @media screen and (min-width: 1200px) {
        width: 525px;
        top: -30px;
        left: -30px;
    }
`

const RefreshTimerContainer = styled.div`
    display: flex;
    margin-top: 1rem;
    margin-bottom: 0.25rem;
    height: 6px;
`

const RefreshTimer = styled.div<{ width: number }>`
    display: flex;
    width: ${(props) => props.width}%;
    background-color: #b9e4ee;
    transition: width 0.2s;
    height: 4px;
    border-radius: 3px;
    box-shadow: 0px 0px 12px #87c6f5;
`

const renderCustomOption = (optionProps: DomProps, option: TokenOption) => (
    <>
        <OptionButton {...optionProps} $index={option.index}>
            <OptionContainer>
                <OptionImageContainer>
                    {option.logoURI && (
                        <img
                            src={option.logoURI}
                            alt={option.name}
                            loading="lazy"
                        />
                    )}
                </OptionImageContainer>
                <OptionDetails>
                    <b>{option.name}</b>
                </OptionDetails>
            </OptionContainer>
            <OptionDetails>
                {option.sublabel && <SubLabel>{option.sublabel}</SubLabel>}
            </OptionDetails>
        </OptionButton>
        <Separator />
    </>
)

const renderCustomValue = (
    valueProps: ValueProps,
    snapshot: { option: TokenOption },
    className: string
) => (
    <OptionInput
        id={'from-token'}
        {...valueProps}
        className={className}
        $snapshot={snapshot}
        placeholder={snapshot?.option?.name}
    />
)

export const JupiterSwap = () => {
    const [tokenList, setTokenList] = useState<TokenOption[]>([])
    const [fromTokenAddr, setFromTokenAddr] = useState(SOLANA_TOKEN_ADDRESS)
    const [amount, setAmount] = useState('')
    const [allTokens, setAllTokens] = useState<{
        [key: string]: JupiterToken
    }>({})
    const [quote, setQuote] = useState<JupiterQuote | null>(null)
    const [lastRefreshTime, setLastRefreshTime] = useState<number | null>(null)
    const [timeDiff, setTimeDiff] = useState(200)
    const [loading, setLoading] = useState(false)
    const [swapTx, setSwapTx] = useState<string | null>(null)
    const { wallet, signTransaction } = useWallet()

    const amountInDecimal = parseStringToDecimal(
        amount,
        allTokens[fromTokenAddr]?.decimals || 1
    )

    useMemo(() => {
        fetch('https://token.jup.ag/all')
            .then((response) => response.json())
            .then((data: JupiterToken[]) => {
                const allTokensMap = {} as { [key: string]: JupiterToken }
                setTokenList(
                    data
                        .reduce((acc, token, index) => {
                            if (token.chainId === MAINNET_BETA_ID) {
                                if (
                                    token.address !== WOOF_TOKEN_ADDRESS &&
                                    index < 40
                                ) {
                                    acc.push({
                                        value: token.address,
                                        name: token.symbol,
                                        sublabel: token.name,
                                        logoURI: token.logoURI,
                                    })
                                }
                                allTokensMap[token.address] = token
                            }
                            return acc
                        }, [] as TokenOption[])
                        .sort((a, b) =>
                            a.name
                                .replace('$', '')
                                .localeCompare(b.name.replace('$', ''))
                        )
                )
                setAllTokens(allTokensMap)
            })
            .catch((err) => {
                console.error(err)
            })
    }, [])

    const refreshQuote = () => {
        if (amountInDecimal && fromTokenAddr && !swapTx) {
            setLoading(true)
            fetch(
                `https://quote-api.jup.ag/v6/quote?inputMint=${fromTokenAddr}&outputMint=${WOOF_TOKEN_ADDRESS}&amount=${amountInDecimal}&slippageBps=50&platformFeeBps=50`
            )
                .then((response) => response.json())
                .then((data) => {
                    if (!fromTokenAddr) {
                        console.error('Invalid from token')
                    }
                    setQuote(data)
                    setLastRefreshTime(new Date().getTime())
                })
                .catch((err) => {
                    console.error(`Error fetching quote!`)
                    console.error(err)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    useEffect(() => {
        refreshQuote()
    }, [fromTokenAddr, amount])

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (loading || !lastRefreshTime) return

            const diff = new Date().getTime() - lastRefreshTime
            setTimeDiff((diff / REFRESH_INTERVAL) * 100)

            if (diff >= REFRESH_INTERVAL) {
                refreshQuote()
            }
        }, 1000)
        return () => clearInterval(intervalId)
    }, [quote])

    useEffect(() => {
        setSwapTx(null)
    }, [amount, fromTokenAddr])

    const performSwap = async () => {
        if (quote && wallet?.adapter?.publicKey && signTransaction) {
            setLoading(true)
            try {
                const txRes = await fetch(`https://quote-api.jup.ag/v6/swap`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        quoteResponse: quote,
                        userPublicKey: wallet.adapter.publicKey.toBase58(),
                        wrapUnwrapSol: true,
                        feeAccount: await getJupiterFeeAccount(
                            quote.outputMint
                        ),
                        computeUnitPriceMicroLamports: 'auto',
                    }),
                })
                const txData = await txRes.json()
                const tx = VersionedTransaction.deserialize(
                    Buffer.from(txData.swapTransaction, 'base64')
                )
                const signedTx = await signTransaction(tx)
                const txId = await connection.sendRawTransaction(
                    signedTx.serialize(),
                    {
                        skipPreflight: true,
                        maxRetries: 2,
                    }
                )
                await connection.confirmTransaction(txId, 'confirmed')
                setSwapTx(txId)
            } catch (err) {
                console.error(err)
                // todo: set swap error
            } finally {
                setLoading(false)
            }
        }
    }

    let outputSize = null

    if (quote && quote.outAmount > 0) {
        outputSize = formatToReadableNumber(
            quote.outAmount / 10 ** (allTokens[quote.outputMint].decimals || 1),
            1
        )
    }

    const inputError = amount && !amountInDecimal
    const jupiterError = quote?.error

    return (
        <SwapContainer>
            <WoofImage src={WoofLogo} alt="WOOF Logo" />
            <SwapHeader>$WOOF QuickSwap</SwapHeader>
            <InputLabel htmlFor="input">From token:</InputLabel>
            <Select
                id="input"
                options={tokenList}
                value={fromTokenAddr}
                search={true}
                renderOption={renderCustomOption}
                renderValue={renderCustomValue}
                onChange={(value) => {
                    setFromTokenAddr(value as string)
                }}
            />

            <InputLabel htmlFor="amount">Amount:</InputLabel>
            <TextInput
                id="amount"
                type="text"
                value={amount}
                onChange={(e) => {
                    setQuote(null)
                    setAmount(e.target.value?.replace(/,/g, '.'))
                }}
            />
            <InputLabel htmlFor="output">You receive:</InputLabel>
            {loading && <Loading />}
            {outputSize && !loading && !swapTx && (
                <OutputContainer>
                    <img
                        src={WoofLogo}
                        style={{ maxWidth: '32px' }}
                        alt="WOOF Logo"
                    />
                    ~{outputSize}
                </OutputContainer>
            )}
            {!outputSize && !loading && (
                <HelpTextContainer>
                    <img
                        src={WoofLogo}
                        style={{ maxWidth: '32px' }}
                        alt="WOOF Logo"
                    />
                    {!amount && 'Please enter amount.'}
                    {inputError && (
                        <Error>
                            Invalid input, please adjust your parameters.
                        </Error>
                    )}
                    {jupiterError && (
                        <Error>
                            Jupiter API error, please adjust your parameters.
                        </Error>
                    )}
                </HelpTextContainer>
            )}
            {swapTx && !loading && (
                <HelpTextContainer>
                    <img
                        src={WoofLogo}
                        style={{ maxWidth: '32px' }}
                        alt="WOOF Logo"
                    />
                    <span style={{ marginRight: '0.25rem' }}>
                        Swap successful!{' '}
                    </span>
                    <Link
                        href={`https://solscan.io/tx/${swapTx}`}
                        rel="noreferrer"
                        target="_blank"
                    >
                        View transaction
                    </Link>
                    .
                </HelpTextContainer>
            )}

            <RefreshTimerContainer>
                {quote && timeDiff && !loading && !swapTx && (
                    <RefreshTimer
                        width={timeDiff < 100 ? 100 - Math.floor(timeDiff) : 0}
                    />
                )}
            </RefreshTimerContainer>

            <SwapButton
                disabled={!wallet?.adapter?.publicKey || loading}
                onClick={performSwap}
            >
                {loading ? 'Processing...' : 'Purchase'}
            </SwapButton>
            <WoofWalletSelectButton />
        </SwapContainer>
    )
}
