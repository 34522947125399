import React from 'react'
import cyberpunk from './img/cyberpunk1.jpeg'
import cyberpunk2 from './img/cyberpunk2.jpeg'
import cyberpunk3 from './img/cyberpunk3.jpeg'
import cyberpunk4 from './img/cyberpunk4.jpeg'
import cyberpunk5 from './img/cyberpunk5.jpeg'
import cyberpunk6 from './img/cyberpunk6.jpeg'
import diamondpaws from './img/diamondpaws.png'
import styled, { keyframes } from 'styled-components'
import { Link } from './components'

const fadeAnim = keyframes`
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.4;
  }
`

const FillerImg = styled.img<{ delay: number }>`
    height: auto;
    max-height: 100px;
    margin-right: 1rem;
    border-radius: 1rem;
    box-shadow: rgb(0, 0, 0) 0px 3px 3px 3px;
    animation: ${fadeAnim} linear 12s infinite ${(props) => props.delay}s;
    opacity: 0.4;

    @media screen and (min-width: 1200px) {
        max-height: 200px;
    }
    @media screen and (min-width: 2000px) {
        max-height: 300px;
    }
`

const ImageContainerLeft = styled.div`
    flex-flow: column;
    margin-right: 2rem;
    display: none;

    div {
        display: flex;
        height: 33%;
    }

    @media screen and (min-width: 1600px) {
        display: flex;
    }
`

const ImageContainerRight = styled.div`
    display: none;
    flex-flow: column;
    margin-left: 2rem;

    div {
        display: flex;
        height: 33%;
        align-items: center;
    }

    @media screen and (min-width: 1600px) {
        display: flex;
    }
`

const TextContainer = styled.div`
    display: flex;
    flex-flow: column;

    p {
        text-align: justify;
    }
`

const AboutContainer = styled.div`
    display: flex;
    flex-flow: row;
`

const Footer = styled.p`
    justify-content: center;
    align-items: center;
    display: flex;
    flex-flow: column;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 2rem;
`
const DiamondPaws = styled.img`
    width: 200px;
    height: auto;
`

const FillerImage = ({
    src,
    delay,
    alt,
}: {
    src: string
    delay: number
    alt: string
}) => (
    <div>
        <FillerImg src={src} alt={alt} delay={delay} />
    </div>
)

export const About = () => {
    return (
        <AboutContainer id="about">
            <ImageContainerLeft>
                <FillerImage src={cyberpunk} alt="WOOF" delay={0} />
                <FillerImage src={cyberpunk2} alt="WOOF" delay={4} />
                <FillerImage src={cyberpunk6} alt="WOOF" delay={8} />
            </ImageContainerLeft>

            <TextContainer>
                <p style={{ marginTop: 0 }}>
                    WoofVentures is a group of volunteers that originates from
                    the WOOF of Solana community that consists mainly of $WOOF
                    and WOOFer NFT holders on Solana. The $WOOF community/meme
                    token was launched in May, 2021 and its history could be
                    described as captivating, unpredictable and rich in
                    friendship. The primary goal of WoofVentures is to preserve
                    what $WOOF was and is all about and to take its legacy to
                    new heights.
                </p>
                <p>
                    $WOOF or WoofVentures does not strive to revolutionalize the
                    financial system like every other Web3 project out there.
                    Please go elsewhere if you are looking for that stuff. $WOOF
                    aims to be a fun, safe, active and truly community-owned
                    entry-level token in the Solana ecosystem. There are many,
                    many community (or especially meme) tokens out there that
                    proclaim to be what $WOOF is, but very few actually stand up
                    to scrutiny and even fewer of those that do were launched
                    before the recent crypto bear market. We love WOOF and its
                    community, and that's the only reason along with our
                    faithful community that the token and the project has
                    survived the difficult journey up to this point.
                </p>
                <p>
                    WoofVentures is not the creator of $WOOF or WOOFers. It is a{' '}
                    <b>true</b> "from the community to the community" effort
                    that is based solely on a volunteer team's desire to make
                    WOOF the top dog on Solana although we have no bad
                    intentions towards any other dogs out there sharing the same
                    space with us. As we all well know, all dogs Woof.
                </p>

                <p>
                    The WoofVentures team holds no ownership over the $WOOF
                    token or the existing WOOF NFT collections and is not in a
                    privileged position of any kind when it comes to the token
                    supply or the team members' holdings. Our group of
                    volunteers shares the same pains and joys as all the
                    thousands of other $WOOF holders out there. This is
                    definitely not the case with most of the meme/community coin
                    projects in this space so make sure to stay safe out there.
                </p>
                <p>
                    The next steps for WoofVentures and WOOF will be to
                    <ol>
                        <li>Execute our marketing plan for the $WOOF token</li>
                        <li>Launch the Woofpack NFT collection</li>
                        <li>
                            Additional utilities for our existing NFT
                            collections.
                        </li>
                        <li>
                            See where we are after that and plan the next steps
                            then
                        </li>
                    </ol>
                    That's the current roadmap. Some could say that this is not
                    even a plan and it's not ambitious at all, but that's not
                    what we are about or what WOOF is about. We strive to do
                    things in a professional fashion despite our limited
                    resources and as such things take time. We also don't like
                    to promise something that depends on earlier steps on our
                    roadmap going according to plan. Nothing has ever gone
                    according to the plan when it comes to $WOOF. In other
                    words, we'll adjust as necessary. The only promise we can
                    make is that we are not quitting anytime soon.
                </p>
                <p>
                    Everyone is welcome to join the WOOF family and now is
                    probably the best time there ever was to do so. Please
                    scroll down for more details on the team, the project, the
                    token and the NFTs associated with WOOF so far.
                </p>
                <p>
                    Lastly, keep your guard up because there are many fake WOOF
                    sites, Discords, etc. out there that want to drain your
                    Solana wallet. Do not <b>ever</b> visit any WOOF-related
                    site or service that is not hosted in the domains{' '}
                    <b>woof.ventures</b> or <b>woofsolana.world</b> or linked to
                    in the{' '}
                    <Link href="https://links.woof.ventures" target="_blank">
                        WOOF link tree
                    </Link>
                    .
                </p>
                <Footer>
                    <DiamondPaws src={diamondpaws} alt="Diamond paws" />
                    <p>WOOF WOOF!</p>
                </Footer>
            </TextContainer>
            <ImageContainerRight>
                <FillerImage src={cyberpunk3} alt="WOOF" delay={2} />
                <FillerImage src={cyberpunk4} alt="WOOF" delay={6} />
                <FillerImage src={cyberpunk5} alt="WOOF" delay={10} />
            </ImageContainerRight>
        </AboutContainer>
    )
}
