import React from 'react'
import {
    HeaderContainer,
    ContentContainer,
    Link,
    Header,
    IconLink,
    DownArrow,
    UpArrow,
} from './components'
import styled from 'styled-components'
import Coin98Icon from './img/coin98.png'
import SarosIcon from './img/saros.png'
import JupiterIcon from './img/jupiter.svg'
import ALRIcon from './img/alr.png'
import PhantomIcon from './img/phantom.png'
import SkullClubIcon from './img/skullclub.jpg'

const ContactContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
`

export const Friends = () => (
    <>
        <HeaderContainer id="friends">
            <UpArrow>
                <Link href="#services">&uArr;</Link>
            </UpArrow>
            <Header>Friends of WOOF</Header>
            <DownArrow>
                <Link href="#contact">&dArr;</Link>
            </DownArrow>
        </HeaderContainer>
        <ContentContainer>
            <ContactContainer>
                <Link
                    href="https://coin98.com"
                    rel="noreferrer"
                    target="_blank"
                >
                    <IconLink src={Coin98Icon} alt="Coin98" />
                </Link>
                <Link
                    href="https://saros.finance"
                    rel="noreferrer"
                    target="_blank"
                >
                    <IconLink src={SarosIcon} alt="Saros" />
                </Link>
                <Link href="https://jup.ag" rel="noreferrer" target="_blank">
                    <IconLink src={JupiterIcon} alt="Jupiter Aggregator" />
                </Link>
                <Link
                    href="https://skullclubnft.com/"
                    rel="noreferrer"
                    target="_blank"
                >
                    <IconLink src={SkullClubIcon} alt="Skull Club" />
                </Link>
                <Link
                    href="https://www.alphaleagueracing.com/"
                    rel="noreferrer"
                    target="_blank"
                >
                    <IconLink src={ALRIcon} alt="Alpha League Racing" />
                </Link>
                <Link
                    href="https://phantom.app"
                    rel="noreferrer"
                    target="_blank"
                >
                    <IconLink src={PhantomIcon} alt="Phantom wallet" />
                </Link>
            </ContactContainer>
        </ContentContainer>
    </>
)
