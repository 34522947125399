import React from 'react'
import WoofGenArt from './img/woof-gen-art.jpg'
import Woofer from './img/woofer.png'
import DigiPups from './img/digipups.png'
import Woofpack from './img/woofpack.jpg'
import styled from 'styled-components'
import {
    HeaderContainer,
    ContentContainer,
    Header,
    Link,
    DownArrow,
    UpArrow,
} from './components'

const NFTImg = styled.img`
    margin-bottom: 1rem;
    border-radius: 1rem;
    max-width: 80%;

    @media screen and (min-width: 992px) {
        max-height: 200px;
        margin-right: 1.5rem;
    }
`

const NFTContainer = styled.div`
    display: flex;
    flex-flow: column;
    margin: 2rem;
    justify-content: center;
    align-items: center;
    width: 100%;

    @media screen and (min-width: 992px) {
        flex: 1;
        flex-flow: row;
        justify-content: start;
        align-items: start;
    }
`
const NFTDetails = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;

    @media screen and (min-width: 992px) {
        width: 750px;
        justify-content: start;
        align-items: start;
    }
`

const NFTBullets = styled.ul`
    padding-left: 1.5rem;
    margin: 0 1rem 0 1rem;
`

const NFTsContainer = styled(ContentContainer)`
    padding: 1rem 0 0 0;
`

const NFTHeader = styled.h2`
    font-family: 'Bebas Neue', sans-serif;
    margin: 1.5rem 0 1.5rem 0;
    font-size: 1.75rem;

    @media screen and (min-width: 992px) {
        margin: 0;
    }
`

export const NFTs = () => (
    <>
        <HeaderContainer id="nfts">
            <UpArrow>
                <Link href="#token">&uArr;</Link>
            </UpArrow>
            <Header>NFT Collections</Header>
            <DownArrow>
                <Link href="#services">&dArr;</Link>
            </DownArrow>
        </HeaderContainer>
        <NFTsContainer>
            <NFTContainer>
                <NFTImg src={WoofGenArt} alt="WOOF - Generated Art Series" />
                <NFTDetails>
                    <NFTHeader>Gen Art Series</NFTHeader>
                    <NFTBullets>
                        <li>Airdropped to $WOOF holders in September, 2021.</li>
                        <li>Supply at launch: 6 421.</li>
                        <li>
                            Available for trading on{' '}
                            <Link
                                href="https://solsea.io/c/61abd1c8880668bc1442973a"
                                rel="noreferrer"
                                target="_blank"
                            >
                                SolSea
                            </Link>
                            .
                        </li>
                    </NFTBullets>
                </NFTDetails>
            </NFTContainer>
            <NFTContainer>
                <NFTImg src={Woofer} alt="WOOFers" />
                <NFTDetails>
                    <NFTHeader>WOOFers</NFTHeader>
                    <NFTBullets>
                        <li>
                            Minted for 0.5 SOL a piece in November - December,
                            2022.
                        </li>
                        <li>Supply at launch: 5 555.</li>
                        <li>Granted access to the now defunct WOOF DAO.</li>
                        <li>
                            Non-custodial staking for $WOOF rewards available on{' '}
                            <Link
                                href="https://staking.woof.ventures"
                                target="_blank"
                                rel="noreferrer"
                            >
                                staking.woof.ventures
                            </Link>
                            .
                        </li>
                        <li>
                            Play around with WOOFer traits on the{' '}
                            <Link
                                href="https://woofers-playground.woof.ventures"
                                target="_blank"
                                rel="noreferrer"
                            >
                                WOOFers playground
                            </Link>
                            .
                        </li>
                        <li>
                            Available for trading on e.g.{' '}
                            <Link
                                href="https://www.tensor.trade/trade/woof"
                                rel="noreferrer"
                                target="_blank"
                            >
                                Tensor
                            </Link>
                            .
                        </li>
                    </NFTBullets>
                </NFTDetails>
            </NFTContainer>
            <NFTContainer>
                <NFTImg src={DigiPups} alt="DigiPups" />
                <NFTDetails>
                    <NFTHeader>DigiPups</NFTHeader>
                    <NFTBullets>
                        <li>Minted for free in April, 2023.</li>
                        <li>Supply at launch: 276.</li>
                        <li>
                            A collaboration with{' '}
                            <Link
                                href="https://twitter.com/AI_LabNFT"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Clouds (AI Labs)
                            </Link>
                            .
                        </li>
                        <li>Each one is unique and has a unique name.</li>
                        <li>
                            Mint tokens were distributed through community
                            games.
                        </li>
                        <li>
                            Available for trading on{' '}
                            <Link
                                href="https://magiceden.io/marketplace/digipup"
                                rel="noreferrer"
                                target="_blank"
                            >
                                Magic Eden
                            </Link>
                            .
                        </li>
                    </NFTBullets>
                </NFTDetails>
            </NFTContainer>
            <NFTContainer>
                <NFTImg src={Woofpack} alt="Woofpack" />
                <NFTDetails>
                    <NFTHeader>Woofpack</NFTHeader>
                    <NFTBullets>
                        <li>Upcoming collection... Stay tuned.</li>
                    </NFTBullets>
                </NFTDetails>
            </NFTContainer>
        </NFTsContainer>
    </>
)
