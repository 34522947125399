import styled, { keyframes } from 'styled-components'

export const Link = styled.a`
    color: #a6d5f2;
    text-decoration: none;
    &:hover {
        color: #c4e0f1;
    }
`

export const Header = styled.h1`
    font-size: 2.5rem;
    font-weight: 500;
    margin: 0;
    font-family: 'Bebas Neue', sans-serif;
    text-transform: uppercase;
`

export const HeaderContainer = styled.div`
    border-radius: 2rem;
    background-color: rgba(64, 64, 64, 0.12);
    display: flex;
    padding: 2rem 0rem 2rem 0rem;
    justify-content: center;
    align-items: center;
    width: 100%;
    box-shadow: rgb(0, 0, 0) 0px 3px 3px 3px;
    position: relative;
`

export const Paragraph = styled.p`
    font-size: 1.5rem;
    line-height: 2.5rem;
    text-align: justify;
    padding: 2rem;
    max-width: 100%;
`

export const ParagraphDark = styled(Paragraph)`
    padding: 2rem;
    border-radius: 0.5rem;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.6);

    @media screen and (min-width: 1200px) {
        max-width: 50%;
        background-color: rgba(0, 0, 0, 0.8);
    }
`

export const MainContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    min-height: 100vh;
    margin-top: 5rem;
    @media screen and (min-width: 578px) {
        margin: 0 10% 0 10%;
    }
`

export const LogoContainer = styled.div`
    margin: 2rem 4rem 2rem 4rem;

    img {
        pointer-events: none;
        max-width: 100%;
        max-height: 100%;
    }
`

export const Section = styled.div`
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-flow: column;
    border-radius: 2rem;
    margin-bottom: 2rem;
    width: 100%;
    box-shadow: rgb(0, 0, 0) 0px 3px 3px 3px;

    @media screen and (min-width: 576px) {
        background-color: rgba(0, 0, 0, 0.9);
    }
`

export const ContentContainer = styled.div`
    display: flex;
    flex-flow: row;
    font-size: 1.325rem;
    line-height: 2.5rem;
    flex-wrap: wrap;
    padding: 2rem 2rem 2rem 2rem;
`

const SeparatorAnim = keyframes`
  0% { background-position: 0%; }
  100% { background-position: 200%; }
`

export const Separator = styled.b<{ $mobileonly: boolean }>`
    display: block;
    width: 100%;
    height: 2px;
    position: relative;

    &:after,
    &:before {
        content: '';
        position: absolute;

        width: 100%;
        height: 2px;
        bottom: 50%;
        left: 0;
    }

    &:before {
        background: linear-gradient(
            90deg,
            #000 0%,
            #000 50%,
            transparent 50%,
            transparent 100%
        );
        background-size: 1px;
        background-position: center;
        z-index: 1;
    }

    &:after {
        transition: opacity 0.3s ease, animation 0.3s ease;

        background: linear-gradient(
            to right,
            #53a8d1 5%,
            #4e9cc2 15%,
            #4489ab 25%,
            #8bc3df 35%,
            #ccc 45%,
            #fff 55%,
            #ccc 65%,
            #8bc3df 75%,
            #4489ab 85%,
            #4e9cc2 95%
        );

        background-size: 200%;
        background-position: 0%;
        animation: ${SeparatorAnim} 10s linear infinite;
    }

    ${(props) =>
        props.$mobileonly
            ? `
  @media screen and (min-width: 992px) {
    display: none;
  }`
            : ''}
`

export const ButtonHoverAnimation = keyframes`
  0% {
    background-color: rgba(22, 45, 56, 0.8);
  }
  50% {
    background-color: rgba(47, 80, 100, 0.8);
  }
  100% {
    background-color: rgba(22, 45, 56, 0.8);
  }
`

export const Button = styled.button`
    width: 100%;
    color: inherit;
    border: none;
    padding: 0;
    cursor: not-allowed;
    font: inherit;
    outline: inherit;
    padding: 1rem;
    &:not([disabled]) {
        cursor: pointer;
        &:hover {
            animation: ${ButtonHoverAnimation} 1s linear infinite;
        }
    }
`

const NavigationArrow = styled.div`
    font-size: 3rem;
    display: flex;
    position: absolute;
`
export const DownArrow = styled(NavigationArrow)`
    right: 20px;
`
export const UpArrow = styled(NavigationArrow)`
    left: 20px;
`

export const IconLink = styled.img`
    height: 50px;
    width: auto;
    margin: 2rem;

    &:hover {
        transform: scale(1.1);
    }

    @media screen and (min-width: 1200px) {
        height: 75px;
    }

    @media screen and (min-width: 1600px) {
        height: 100px;
    }
`
