import React, { useMemo } from 'react'
import styled from 'styled-components'
import {
    WalletProvider,
    ConnectionProvider,
} from '@solana/wallet-adapter-react'
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base'
import {
    Coin98WalletAdapter,
    HuobiWalletAdapter,
    LedgerWalletAdapter,
    PhantomWalletAdapter,
    SolflareWalletAdapter,
    TorusWalletAdapter,
} from '@solana/wallet-adapter-wallets'
import {
    WalletModalProvider,
    WalletMultiButton,
} from '@solana/wallet-adapter-react-ui'
import { ButtonHoverAnimation } from './components'

// Default styles that can be overridden by your app
require('@solana/wallet-adapter-react-ui/styles.css')

const WalletButtonContainer = styled.div`
    margin-top: 1.5rem;
    .wallet-adapter-button {
        background-color: rgba(22, 45, 56, 0.4);
        border: 1px solid rgba(22, 45, 56, 0.4);
        z-index: 1;
        border-radius: 0;
        width: 100%;
        font-weight: 400;
        font-size: 1.5rem;
        font-family: inherit;
        justify-content: center;
        align-content: center;
        height: auto;
        padding: 1rem;

        &:not([disabled]) {
            &:hover {
                animation: ${ButtonHoverAnimation} 1s linear infinite;
            }
        }

        &:disabled {
            background: rgba(220, 31, 255, 0.9);
            background: linear-gradient(
                90deg,
                rgba(220, 31, 255, 0.9) 0%,
                rgba(6, 252, 178, 0.9) 100%
            );
            color: #fff;
        }
    }

    .wallet-adapter-dropdown {
        width: 100%;
        .wallet-adapter-dropdown-list-item {
            &:not([disabled]) {
                &:hover {
                    background-color: #2c2d30;
                    color: #06fcb2;
                }
            }
        }
    }

    @media screen and (min-width: 992px) {
        margin-right: 0;
    }
`

export const WOOF_RPC_ENDPOINT =
    'https://indulgent-delicate-cherry.solana-mainnet.discover.quiknode.pro/344a1a4563b543693b859b243976092a4c71b212/'

export const Wallet = (props: React.PropsWithChildren) => {
    // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
    const network = WalletAdapterNetwork.Mainnet

    const wallets = useMemo(
        () => [
            /**
             * Wallets that implement either of these standards will be available automatically.
             *
             *   - Solana Mobile Stack Mobile Wallet Adapter Protocol
             *     (https://github.com/solana-mobile/mobile-wallet-adapter)
             *   - Solana Wallet Standard
             *     (https://github.com/solana-labs/wallet-standard)
             *
             * If you wish to support a wallet that supports neither of those standards,
             * instantiate its legacy wallet adapter here. Common legacy adapters can be found
             * in the npm package `@solana/wallet-adapter-wallets`.
             */
            new PhantomWalletAdapter(),
            new Coin98WalletAdapter(),
            new SolflareWalletAdapter(),
            new LedgerWalletAdapter(),
            new TorusWalletAdapter(),
            new HuobiWalletAdapter(),
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [network]
    )

    return (
        <ConnectionProvider endpoint={WOOF_RPC_ENDPOINT}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>{props.children}</WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    )
}

export const WoofWalletSelectButton = () => (
    <WalletButtonContainer className="woof-connect-wallet-container">
        <WalletMultiButton />
    </WalletButtonContainer>
)
