import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useCookies } from 'react-cookie'

const Overlay = styled.div`
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    margin: 0;
    z-index: 4;
    overflow-y: auto;

    @media screen and (min-width: 992px) {
        justify-content: center;
        align-items: center;
    }
`
const Dialog = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;

    @media screen and (min-width: 992px) {
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
    }
`

const DisclaimerTextContainer = styled.div`
    font-family: 'Bebas Neue', sans-serif;
    color: #fff;
    display: flex;
    height: auto;
    border-radius: 1rem;
    border: 1px solid #353535;
    padding: 2rem;
    font-size: 1.125rem;
    text-align: justify;
    flex-flow: column;
    background-color: rgba(15, 15, 15, 0.9);
    overflow-y: auto;
    max-width: 1200px;

    p {
        padding-top: 1rem;
    }
`

const ButtonContainer = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    font-size: 1rem;
`

const Button = styled.button`
    margin-top: 1rem;
    color: #9a9a9b;
    background: transparent;
    border: 1px solid #434343;
    border-radius: 2px;
    box-shadow: 0 2px 0 rgba(0,0,0,.015);
    color: hsla(0,0%,100%,.85);
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    height: 32px;
    line-height: 1.5715;
    padding: 4px 15px;
    position: relative;
    text-align: center;
    touch-action: manipulation;
    transition: all .3s cubic-bezier(.645,.045,.355,1);
    user-select: none;
    white-space: nowrap;
    font-family: inherit;

    &:hover {
        text-decoration: none;
        border: 1px solid #06fcb2;
        color: #fff;
    }
  }

`

const WOOF_CONSENT_COOKIE_NAME = 'WoofVenturesTOSConsent'

export const WoofDisclaimer = () => {
    const [cookies, setCookie] = useCookies([WOOF_CONSENT_COOKIE_NAME])
    const [isOpen, setOpen] = useState<Boolean>(
        cookies?.WoofVenturesTOSConsent !== 'true'
    )
    useEffect(() => {
        const consentMissing = cookies?.WoofVenturesTOSConsent !== 'true'
        document.body.style.overflow = consentMissing ? 'hidden' : 'auto'
        setOpen(consentMissing)
    }, [cookies?.WoofVenturesTOSConsent])

    return isOpen ? (
        <Overlay>
            <Dialog>
                <DisclaimerTextContainer>
                    <p>
                        This website is maintained by Woof community volunteers.
                        The contents and opinions of this website are those of
                        Woof community. Woof community does not warrant that the
                        information provided by this website is correct,
                        complete, and up-to-date. Woof community is not
                        responsible for their content and expressly rejects any
                        liability for damages of any kind resulting from the
                        use, reference to, or reliance on any information
                        contained within these websites. This website does not
                        constitute an offer to purchase or solicitation to sell,
                        nor is it a recommendation to buy or sell, any token or
                        other product. Purchasing and selling tokens is
                        inherently risky and holders must bear their risk of
                        loss (including total loss).
                    </p>
                    <p>
                        Neither the authors of this website, Woof community nor
                        any participants in the WOOF project accept any
                        liability for losses or taxes that holders, purchasers
                        or sellers of $WOOF may incur.
                    </p>
                    <p>
                        WOOF NFTs (including WOOFers) are released as
                        collectibles only and are authentic artworks for this
                        purpose. WOOF products (including without limitation the
                        $WOOF token, NFTs and any staking or rewards thereon)
                        are not available to residents of China, Belarus, The
                        Central African Republic, The Democratic Republic of
                        Congo, The Democratic People's Republic of Korea, The
                        Crimea region of Ukraine, Cuba, Iran, Libya, Somalia,
                        Sudan, South Sudan, Syria, The USA, Yemen, and Zimbabwe
                        or any other jurisdiction in which accessing or using
                        the WOOF products are prohibited or limited by laws or
                        regulations (“Prohibited Jurisdiction"). In using the
                        WOOF products, you confirm that you do not reside in a
                        Prohibited Jurisdiction. Consult your local laws before
                        transacting in any cryptocurrency.
                    </p>
                    <ButtonContainer>
                        <Button
                            onClick={() => {
                                setCookie(WOOF_CONSENT_COOKIE_NAME, true, {
                                    domain: process.env.SITE_DOMAIN?.includes(
                                        'woof.ventures'
                                    )
                                        ? 'woof.ventures'
                                        : process.env.SITE_DOMAIN,
                                    sameSite: 'strict',
                                })
                            }}
                        >
                            I understand and accept the terms of service
                        </Button>
                    </ButtonContainer>
                </DisclaimerTextContainer>
            </Dialog>
        </Overlay>
    ) : null
}
