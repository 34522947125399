import React from 'react'
import WoofDex from './img/dex.png'
import Playground from './img/playground.png'
import Staking from './img/woofers-staking.png'
import styled from 'styled-components'
import {
    HeaderContainer,
    ContentContainer,
    Header,
    Link,
    DownArrow,
    UpArrow,
} from './components'

const ServiceImg = styled.img`
    margin-bottom: 1rem;
    border-radius: 1rem;
    max-height: 250px;
    opacity: 0.75;

    @media screen and (min-width: 992px) {
        max-height: 150px;
        margin-right: 1.5rem;
    }
`

const ServiceContainer = styled.div`
    display: flex;
    flex-flow: column;
    margin: 2rem;
    justify-content: center;
    align-items: center;
    width: 100%;

    @media screen and (min-width: 992px) {
        flex: 1;
        flex-flow: row;
        justify-content: start;
        align-items: start;
    }
`
const ServiceDetails = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;

    @media screen and (min-width: 992px) {
        width: 750px;
        justify-content: start;
        align-items: start;
    }
`

const ServiceBullets = styled.ul`
    padding-left: 1.5rem;
    margin: 0 1rem 0 1rem;
`

const ServicesContainer = styled(ContentContainer)`
    padding: 1rem 0 0 0;
`

const ServiceHeader = styled.h2`
    font-family: 'Bebas Neue', sans-serif;
    margin: 1.5rem 0 1.5rem 0;
    font-size: 1.75rem;

    @media screen and (min-width: 992px) {
        margin: 0;
    }
`

export const Services = () => (
    <>
        <HeaderContainer id="services">
            <UpArrow>
                <Link href="#nfts">&uArr;</Link>
            </UpArrow>
            <Header>Services</Header>
            <DownArrow>
                <Link href="#friends">&dArr;</Link>
            </DownArrow>
        </HeaderContainer>
        <ServicesContainer>
            <ServiceContainer>
                <ServiceImg src={WoofDex} alt="WOOF DEX" />
                <ServiceDetails>
                    <ServiceHeader>WOOF DEX</ServiceHeader>
                    <ServiceBullets>
                        <li>
                            An entry-level, low-fee DEX for trading SPL tokens.
                        </li>
                        <li>Mobile-friendly.</li>
                        <li>
                            Supports limit orders via Openbook central order
                            book and instant swaps via Jupiter aggregator API.
                        </li>
                        <li>
                            <Link
                                href="https://dex.woof.ventures"
                                target="_blank"
                                rel="noreferrer"
                            >
                                https://dex.woof.ventures
                            </Link>
                        </li>
                    </ServiceBullets>
                </ServiceDetails>
            </ServiceContainer>
            <ServiceContainer>
                <ServiceImg src={Playground} alt="WOOFers' playground" />
                <ServiceDetails>
                    <ServiceHeader>WOOFers' Playground</ServiceHeader>
                    <ServiceBullets>
                        <li>
                            Come up with your dream WOOFer by selecting any
                            combination of traits.
                        </li>
                        <li>Includes a rarity table for WOOFers.</li>
                        <li>Does not support actually minting NFTs.</li>
                        <li>
                            <Link
                                href="https://woofers-playground.woof.ventures"
                                target="_blank"
                                rel="noreferrer"
                            >
                                https://woofers-playground.woof.ventures
                            </Link>
                        </li>
                    </ServiceBullets>
                </ServiceDetails>
            </ServiceContainer>
            <ServiceContainer>
                <ServiceImg src={Staking} alt="WOOFer staking" />
                <ServiceDetails>
                    <ServiceHeader>WOOFer staking</ServiceHeader>
                    <ServiceBullets>
                        <li>Stake your WOOFer to earn some $WOOF.</li>
                        <li>
                            Non-custodial; Your WOOFers stay in your wallet.
                        </li>
                        <li>
                            Rewards depend on rarity score on{' '}
                            <Link
                                href="https://moonrank.app/collection/woof"
                                target="_blank"
                                rel="noreferrer"
                            >
                                MoonRank
                            </Link>
                            .
                        </li>
                        <li>Rewards can be withdrawn anytime.</li>
                        <li>
                            {' '}
                            <Link
                                href="https://staking.woof.ventures"
                                target="_blank"
                                rel="noreferrer"
                            >
                                https://staking.woof.ventures
                            </Link>
                        </li>
                    </ServiceBullets>
                </ServiceDetails>
            </ServiceContainer>
        </ServicesContainer>
    </>
)
